import { Box, Container, Grid, Snackbar, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import classNames from "classnames";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { useIntercom } from "react-use-intercom";
import moment from "moment";
import qs from "qs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import useAsyncEffect from "use-async-effect";
import { v4 as uuidv4 } from "uuid";

import { getTimeslots } from "../../../api/scheduling";
import {
  MM_DD_YYYY,
  YYYY_MM_DD_ALTERNATIVE,
} from "../../../constants/dateFormat";
import { PATHNAMES } from "../../../constants/pathnames";
import * as commonMaterial from "../../../materialDesignShared";
import { toastLength } from "../../../services/constants";
import { weekInJourney } from "../../../services/journey-helpers";
import { appointmentStore } from "../../../stores/appointment-store";
import { authenticationStore } from "../../../stores/authentication-store";
import { ProgressBarLoggedOut } from "../ProgressBarLoggedOut/ProgressBarLoggedOut";

import { getDashboard } from "../../../api/dashboard";
import { getDepartments } from "../../../api/departments";
import { sonoShortnames } from "../../../constants/sonoShortnames";
import "./Location.scss";
import goBackHelper from "./goBackHelper";
import { useHistoryWithPathBasedReload } from "../../App/LinkWithPathBasedReload";
import { useFeatureFlag } from "../../../providers/LaunchDarklyProvider";

const DEFAULT_END_SLICE = 7;

export const Location = observer(() => {
  const { show } = useIntercom();
  const history = useHistoryWithPathBasedReload();
  const { t: translations } = useTranslation();
  const [isVirtual, setIsVirtual] = useState(null);
  const [appointmentSlots, setAppointmentSlots] = useState([]);
  const [startWeekDate, setStartWeekDate] = useState(null);
  const [weekInJourneyAppt, setWeekInJourneyAppt] = useState(null);
  const [earliestStartDay, setEarliestStartDay] = useState(
    moment().add(1, "days")
  );
  const [appointmentTypeId, setAppointmentTypeId] = useState(null);
  const [appointmentType, setAppointmentType] = useState("");
  const [appointmentShortname, setAppointmentShortname] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [departmentInfo, setDepartmentInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startSlicing, setStartSlicing] = useState(0);
  const [endSlicing, setEndSlicing] = useState(DEFAULT_END_SLICE);
  const [toast, setToast] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [publicConfirmation, setPublicConfirmation] = useState(false);
  const [slots, setSlots] = useState([]);
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  moment.suppressDeprecationWarnings = true;
  const hoursRef = useRef([]);
  const noAvailabilityHelpText = translations("no_availability_help");
  const locationContainerClassName = classNames(
    "location-scheduling-container",
    {
      public: !authenticationStore.userId,
      confirmation: publicConfirmation,
    }
  );

  useAsyncEffect(async () => {
    const { data: departmentsInfo } = await getDepartments();
    let departmentIdStorage = appointmentStore.getDepartmentId();
    const appointment = appointmentStore.getAppointment();

    // we need to override the department ID for sonography appointments
    const shortname =
      appointment.appointment_type_shortname || appointment.shortName;

    if (departmentIdStorage) {
      const selectedDepartmentInfo = departmentsInfo?.find(
        (department) =>
          department.emr_departmentid === parseInt(departmentIdStorage)
      );

      setDepartmentId(departmentIdStorage);
      setDepartmentInfo(selectedDepartmentInfo);
    } else if (authenticationStore.userId) {
      const { data } = await getDashboard(authenticationStore?.userId);
      const fetchedDashboardData = get(data, "dashboard");
      let emrDepartmentId = get(
        fetchedDashboardData,
        "patient.department.emr_departmentid",
        departmentIdStorage
      );

      const selectedDepartmentInfo = departmentsInfo?.find(
        (department) =>
          department.emr_departmentid === parseInt(emrDepartmentId)
      );

      setDepartmentId(emrDepartmentId);
      setDepartmentInfo(selectedDepartmentInfo);
    }

    setAppointmentTypeId(query.appointment_type_id);
    setAppointmentShortname(appointment?.shortName);
    setAppointmentType(appointment?.appointment_type_description);
    setStartWeekDate(appointment?.begin_date_range);
    setIsVirtual(appointment?.card_is_virtual || appointment?.virtual_link);
    setWeekInJourneyAppt(
      weekInJourney(appointment?.begin_date_range) > 0
        ? weekInJourney(appointment?.begin_date_range)
        : null
    );
    setReschedule(appointment?.reschedule);

    if (!query.appointment_type_id || !appointment?.begin_date_range) {
      history.goBack();
    }

    if (appointment?.slot_taken) {
      setToast("Slot is already taken. Please, pick another.");
    }

    await updateAvailableTimeslots(
      query.appointment_type_id,
      appointment?.begin_date_range,
      true,
      departmentIdStorage
    );
  }, []);

  useEffect(() => {
    const currentSlots = appointmentSlots.slice(startSlicing, endSlicing);

    hoursRef.current = hoursRef.current.slice(0, appointmentSlots.length);
    setSlots(currentSlots);
  }, [appointmentSlots]);

  useEffect(() => {
    const currentSlots = appointmentSlots.slice(startSlicing, endSlicing);

    setSlots(currentSlots);
  }, [startSlicing, endSlicing]);

  useEffect(() => {
    const timeSlots = slots.map((each) => each.timeslots);
    const areAvailableSlots = !checkEmptySlots(timeSlots);

    if (timeSlots.length && !areAvailableSlots) {
      onClickNextArrow();
    }
  }, [slots]);

  useAsyncEffect(async () => {
    if (query.error) {
      setPublicConfirmation(false);
      if (query.error === "slot_taken") {
        setToast(
          "We’re sorry. That appointment is taken. Please select another time."
        );
      } else {
        setToast(
          "We’re sorry. We had trouble booking that appointment. Please try again."
        );
      }
    }
  }, []);

  const updateAvailableTimeslots = async (
    appointmentId,
    startDate,
    pasteSuf,
    departmentId
  ) => {
    try {
      setLoading(true);
      const { data } = await getTimeslots(
        appointmentId,
        startDate,
        departmentId
      );

      const appointment_slots_raw = data.appointment_slots;

      // if the array starts w/ empty arrays *but* has avail slots later on,
      // remove the start so we don't show a confusing no avail message when there is avail
      const availExists =
        appointment_slots_raw.filter(({ timeslots }) =>
          timeslots.length > 0 ? true : false
        ).length > 0;

      let appointment_slots;
      let firstNonEmptyIndex = undefined;
      if (availExists) {
        appointment_slots_raw.forEach(({ timeslots }, i) => {
          if (timeslots.length > 0 && firstNonEmptyIndex === undefined) {
            firstNonEmptyIndex = i;
          }
        });
        appointment_slots = appointment_slots_raw.slice(firstNonEmptyIndex);
      } else {
        appointment_slots = appointment_slots_raw;
      }

      const firstAvailDate = appointment_slots[firstNonEmptyIndex].date;

      const timeSlots = appointment_slots.map((each) => each.timeslots);
      const areAvailableSlots = !checkEmptySlots(timeSlots);

      if (appointment_slots && areAvailableSlots) {
        if (pasteSuf) {
          let helpArray = appointment_slots;
          const uniqArray = [...new Set(helpArray.map(JSON.stringify))].map(
            JSON.parse
          );
          setAppointmentSlots(uniqArray);
          const findDate = moment(startDate).format(MM_DD_YYYY);
          const noAvailBeforeFindDate =
            moment(firstAvailDate).isAfter(findDate);
          const startIndex = noAvailBeforeFindDate
            ? // since the array has already been filtered, we can use index 0 if there's availability w/in our target
              0
            : uniqArray.findIndex((item) => moment(item.date).isSame(findDate));
          let endIndex = startIndex + 7;
          if (
            moment(moment(uniqArray[0].date).format(MM_DD_YYYY)).isSame(
              moment(moment().add(1, "days").format(YYYY_MM_DD_ALTERNATIVE))
            )
          ) {
            endIndex =
              uniqArray.findIndex((item) =>
                moment(item.date).isSame(
                  moment(findDate).endOf("isoWeek").format(MM_DD_YYYY)
                )
              ) + 1;
          }
          setStartSlicing(startIndex);
          setStartWeekDate(appointment_slots[startIndex].date);
          setEndSlicing(endIndex);
        } else {
          let helpArray = appointment_slots;
          const uniqArray = [...new Set(helpArray.map(JSON.stringify))].map(
            JSON.parse
          );
          setAppointmentSlots(uniqArray);

          const noAvailBeforeFindDate = moment(firstAvailDate).isAfter(
            moment(startWeekDate).subtract(1, "week")
          );
          let startIndex = noAvailBeforeFindDate
            ? firstNonEmptyIndex
            : uniqArray.findIndex((item) =>
                moment(item.date).isSame(
                  moment(
                    moment(startWeekDate).subtract(1, "week").format(MM_DD_YYYY)
                  )
                )
              );
          let endIndex = startIndex + 7;
          if (
            moment(moment(uniqArray[0].date).format(MM_DD_YYYY)).isSame(
              moment(moment().add(1, "days").format(MM_DD_YYYY))
            )
          ) {
            startIndex = uniqArray.findIndex((item) =>
              moment(item.date).isSame(
                moment(
                  moment(moment(startWeekDate).subtract(1, "week"))
                    .startOf("isoWeek")
                    .format(MM_DD_YYYY)
                )
              )
            );
            if (startIndex < 0) {
              startIndex = 0;
            }
            endIndex =
              uniqArray.findIndex((item) =>
                moment(item.date).isSame(
                  moment(
                    moment(moment(startWeekDate).subtract(1, "week"))
                      .endOf("isoWeek")
                      .format(MM_DD_YYYY)
                  )
                    .endOf("isoWeek")
                    .format(MM_DD_YYYY)
                )
              ) + 1;
          }
          setStartSlicing(startIndex);
          setEndSlicing(endIndex);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const timeslots = slots.map(
    (appointmentSlot, i) =>
      appointmentSlot.timeslots.length > 0 && (
        <div className="day-in-week" key={uuidv4()}>
          <div className="day-and-date">
            {moment(appointmentSlot.date).format("dddd")},{" "}
            {moment(appointmentSlot.date).format("MMMM")}{" "}
            {moment(appointmentSlot.date).format("D")}
          </div>
          <div className="timeslot-container">
            <div id={`before-blur-${i}`} className="hours-container-before" />
            <img
              onClick={() => handleScroll("left", i)}
              className="left-arrow"
              src="/icons/Arrow-Left-Main@0,5x.svg"
              alt="arrow-left-icon"
            />
            <div
              className="hours-container"
              ref={(el) => (hoursRef.current[i] = el)}
              key={i}
            >
              {appointmentSlot.timeslots.map((timeslot) => (
                <div
                  className="hours"
                  key={uuidv4()}
                  onClick={() => {
                    if (authenticationStore.getTaggedAppointment() === "true") {
                      setPublicConfirmation(true);
                    }
                    appointmentStore.setAppointment({
                      ...appointmentStore.getAppointment(),
                      timeslot: { ...timeslot },
                    });

                    authenticationStore.userId
                      ? history.push(
                          `${PATHNAMES.CONFIRM}?appointment_type_id=${appointmentTypeId}&emr_departmentid=${departmentId}&book_auth=false`
                        )
                      : history.push(
                          `${PATHNAMES.SIGN_UP}?appointment=true&emr_departmentid=${departmentId}&book_auth=false`
                        );
                  }}
                >
                  {moment.utc(timeslot.start_time).format("hh:mm A")}
                </div>
              ))}
            </div>
            <img
              onClick={() => handleScroll("right", i)}
              className="right-arrow"
              src="/icons/Arrow-Right-Main@0,5x.svg"
              alt="arrow-left-icon"
            />
            <div id={`after-blur-${i}`} className="hours-container-after" />
          </div>
        </div>
      )
  );

  const checkEmptySlots = (slots) =>
    slots.every((slot) => slot === false || slot?.length === 0);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast("");
  };

  function sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  const handleScroll = (direction, i) => {
    if (hoursRef) {
      if (direction === "left") {
        sideScroll(hoursRef.current[i], "left", 25, 100, 10);
      } else {
        if (direction === "right") {
          sideScroll(hoursRef.current[i], "right", 25, 100, 10);
        }
      }
      let slideTimer = setTimeout(function () {
        hoursRef.current[i].scrollLeft > 0
          ? (document.getElementById(`before-blur-${i}`).style.visibility =
              "visible")
          : (document.getElementById(`before-blur-${i}`).style.visibility =
              "hidden");
        hoursRef.current[i].scrollLeft + hoursRef.current[i].clientWidth ===
        hoursRef.current[i].scrollWidth
          ? (document.getElementById(`after-blur-${i}`).style.visibility =
              "hidden")
          : (document.getElementById(`after-blur-${i}`).style.visibility =
              "visible");
      }, 250);
    }
  };

  const onClickPrevArrow = () => {
    let indexStartOfPreviousWeek = startSlicing - DEFAULT_END_SLICE;

    if (
      moment(moment(appointmentSlots[0].date).format(MM_DD_YYYY)).isSame(
        moment(moment().add(1, "days").format(MM_DD_YYYY))
      )
    ) {
      indexStartOfPreviousWeek =
        startSlicing - 1 > 0
          ? appointmentSlots.findIndex((item) =>
              moment(item.date).isSame(
                moment(appointmentSlots[startSlicing - 1].date)
                  .startOf("isoWeek")
                  .format(MM_DD_YYYY)
              )
            )
          : -1;
    }
    let startDate = moment();
    let date = moment(moment(startWeekDate).subtract(1, "week")).format(
      MM_DD_YYYY
    );
    if (indexStartOfPreviousWeek < 0) {
      if (
        moment(appointmentSlots[0].date)
          .subtract(1, "month")
          .isAfter(earliestStartDay)
      ) {
        startDate = moment(appointmentSlots[0].date)
          .subtract(1, "month")
          .format(YYYY_MM_DD_ALTERNATIVE);
      } else {
        startDate = earliestStartDay;
        date = earliestStartDay;
      }
      updateAvailableTimeslots(
        appointmentTypeId,
        startDate,
        false,
        departmentId
      );
      setStartWeekDate(date);
      setWeekInJourneyAppt(weekInJourneyAppt > 1 ? weekInJourney(date) : null);
    } else if (indexStartOfPreviousWeek >= 0) {
      setStartSlicing(indexStartOfPreviousWeek);
      setEndSlicing(indexStartOfPreviousWeek + 7);
      setStartWeekDate(appointmentSlots[indexStartOfPreviousWeek].date);
      setWeekInJourneyAppt(
        weekInJourneyAppt > 1
          ? weekInJourney(appointmentSlots[indexStartOfPreviousWeek].date)
          : null
      );
    }
  };

  const onClickNextArrow = () => {
    let diff = DEFAULT_END_SLICE;

    if (!appointmentSlots.length) return;

    if (endSlicing + diff > appointmentSlots.length - 1) {
      diff = moment(appointmentSlots[appointmentSlots.length - 1].date)
        .add(1, "days")
        .diff(moment(startWeekDate), "days");
      let startDate = moment(appointmentSlots[endSlicing]?.date).format(
        YYYY_MM_DD_ALTERNATIVE
      );
      if (
        moment(moment(appointmentSlots[0].date).format(MM_DD_YYYY)).isSame(
          moment(moment().add(1, "days").format(MM_DD_YYYY))
        )
      ) {
        startDate = moment(
          moment(appointmentSlots[appointmentSlots.length - 1].date)
            .add(1, "days")
            .format(YYYY_MM_DD_ALTERNATIVE)
        )
          .startOf("isoWeek")
          .format(YYYY_MM_DD_ALTERNATIVE);
      }

      updateAvailableTimeslots(
        appointmentTypeId,
        startDate,
        true,
        departmentId
      );
      setStartWeekDate(startDate);
      setWeekInJourneyAppt(
        weekInJourneyAppt > 1 ? weekInJourney(startDate) : null
      );
    } else {
      setStartSlicing(endSlicing);
      setEndSlicing(endSlicing + diff);
      setStartWeekDate(appointmentSlots[endSlicing].date);
      setWeekInJourneyAppt(
        weekInJourneyAppt < 42 && weekInJourneyAppt
          ? weekInJourney(appointmentSlots[endSlicing].date)
          : null
      );
    }
  };

  let firstDayOfWeek = startWeekDate;
  const dayOfWeek = moment(firstDayOfWeek).isoWeekday();

  if (dayOfWeek > 1) {
    firstDayOfWeek = moment(firstDayOfWeek)
      .subtract(dayOfWeek - 1, "days")
      .format(YYYY_MM_DD_ALTERNATIVE);
  }

  const dateStringOpts = {
    day: "numeric",
    month: "long",
  };
  const startDay = new Date(slots[0]?.date).toLocaleDateString(
    undefined,
    dateStringOpts
  );
  const endDay = new Date(slots[slots.length - 1]?.date).toLocaleDateString(
    undefined,
    dateStringOpts
  );
  const dateStringsAreValid = slots.length > 0;

  let noAvailText;
  switch (appointmentShortname) {
    case "DAV": {
      noAvailText = (
        <div>
          Appointment times shown are when you will be between 8 and 14 weeks
          pregnant. Not seeing the time or date you need?{" "}
          <button className="link-button" onClick={show}>
            Message us
          </button>{" "}
          and we'll try to help.
        </div>
      );
      break;
    }
    case "CAS": {
      noAvailText = (
        <div>
          Appointment times shown are when you will be at least 20 weeks
          pregnant. Not seeing the time or date you need?{" "}
          <button className="link-button" onClick={show}>
            Message us
          </button>{" "}
          and we'll try to help.
        </div>
      );
      break;
    }
    case "OFFI": {
      noAvailText = (
        <div>
          Appointment times shown are when you will be at least 10 weeks
          pregnant. Not seeing the time or date you need?{" "}
          <button className="link-button" onClick={show}>
            Message us
          </button>{" "}
          and we'll try to help.
        </div>
      );
      break;
    }
    default: {
      noAvailText = (
        <div>
          Not seeing the time or date you want?{" "}
          <button className="link-button" onClick={show}>
            Message us
          </button>{" "}
          and we'll try to help.
        </div>
      );
    }
  }

  let departmentDisplay = "";
  const { upperWestSideRedirect } = useFeatureFlag("upper-west-side-redirect");
  const uwsRedirect =
    upperWestSideRedirect && !isVirtual && departmentId === 11;

  if (uwsRedirect) departmentDisplay = "Downtown Manhattan 202 Spring Street";
  else
    departmentDisplay = `${departmentInfo?.department_name} ${departmentInfo?.display_name}`;

  return (
    <>
      <ThemeProvider theme={commonMaterial.theme}>
        <Container
          className={locationContainerClassName}
          style={{ marginBottom: "3rem" }}
        >
          <Grid
            justifyContent="center"
            alignContent="center"
            direction="column"
            container
            // frontend rule number one is don't talk about having one component handle 2 completely different routes
            xs={authenticationStore.userId ? 12 : null}
            sm={authenticationStore.userId ? 8 : null}
            md={authenticationStore.userId ? 6 : 12}
          >
            <div
              className={`location-scheduling-title ${
                authenticationStore.getTaggedAppointment() === null &&
                authenticationStore.userId
                  ? ``
                  : `public`
              } ${publicConfirmation ? `confirmation` : ``}`}
            >
              <span>{`Select ${reschedule ? "new" : ""}`} time</span>
            </div>

            {(!authenticationStore.userId ||
              (authenticationStore.userId &&
                authenticationStore.getTaggedAppointment() === "true")) && (
              <div
                className="progress-bar-logged"
                style={{ marginBottom: "0" }}
              >
                <ProgressBarLoggedOut step={2} />
              </div>
            )}

            <Box width={"40rem"} maxWidth={"90vw"}>
              {departmentInfo && (
                <div className="location__departmentCardContainer">
                  {appointmentType && (
                    <Typography className="location__departmentCardTitle location__departmentCardTitle--main">
                      {appointmentType?.toUpperCase()}
                    </Typography>
                  )}

                  <Typography
                    className={`location__departmentCardTitle ${
                      isVirtual ? "location__departmentCardTitle--center" : ""
                    }`}
                  >
                    {departmentDisplay}
                  </Typography>
                </div>
              )}

              {isVirtual && (
                <div className="location__virtualBackground">
                  <div className="location__virtualContainer">
                    <i className="fa-solid fa-laptop location__iconVirtual" />
                    <span className="location__virtualText">
                      Virtual Appointment
                    </span>
                  </div>
                </div>
              )}

              <div
                className={`location-container ${
                  reschedule ? "reschedule" : ""
                }`}
                style={{ marginBottom: "0.6rem" }}
              >
                <div className="time-container">
                  <div className="this-week-container">
                    {!loading && (
                      <img
                        style={{
                          display: startSlicing <= 0 && "none",
                        }}
                        onClick={onClickPrevArrow}
                        src="/icons/Arrow-Left@0,5x.svg"
                        alt="arrow-left-icon"
                      />
                    )}
                    <div className="title">
                      {dateStringsAreValid && `${startDay} - ${endDay}`}
                    </div>
                    {!loading && (
                      <img
                        style={{
                          display: appointmentSlots.length < 1 && "none",
                        }}
                        onClick={onClickNextArrow}
                        src="/icons/Arrow-Right@0,5x.svg"
                        alt="arrow-right-icon"
                      />
                    )}
                  </div>
                  {checkEmptySlots(timeslots) && !loading && (
                    <div className="warning-message">
                      There's no availability for this week. Please try a
                      different week.
                    </div>
                  )}
                  {!checkEmptySlots(timeslots) && !loading && timeslots}
                  <div className="loader">
                    <span style={{ display: !loading && "none" }}>
                      <ClipLoader
                        color={"#ba624a"}
                        loading={loading}
                        size={30}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Box>
            {!loading && <div className="help-message">{noAvailText}</div>}

            <div
              className={`go-back ${
                authenticationStore.userId ? "" : "go-back-auth"
              } `}
              onClick={() => goBackHelper(history)}
            >
              Go back
            </div>
          </Grid>
        </Container>
        <Snackbar
          open={!!toast}
          autoHideDuration={toastLength}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleSnackbarClose} severity={"error"}>
            {toast}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </>
  );
});
